<template>
  <App class="home-view">
    <template #app_bar>
      <AppBar></AppBar>
    </template>
    <template #main>
      <v-container class="home-main">
        <v-card
          class="rounded-lg"
          style="margin:20px; padding: 10px;"
        >
          <v-card-title>
            <v-row class="d-flex justify-center">
              <h3>登入</h3>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row no-gutters align="center" style="margin: 5px;">
              <v-col cols="3" class="justify-start">帳號</v-col>
              <v-col cols="9" class="justify-center">
                <v-text-field
                  v-model="account"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" style="margin: 5px;">
              <v-col cols="3" class="justify-start">密碼</v-col>
              <v-col cols="9" class="justify-center">
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center">
              <v-btn
                rounded
                :dark="account !== '' && password !== ''"
                color="blue"
                width="80%"
                @click="Login"
                :disabled="account === '' || password === ''"
              >
                登入
              </v-btn>
            </v-row>
            <v-row v-if="loginResult" class="dialog-row d-flex justify-center">
              {{loginResult}}
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </template>
    <!--template #footer>
    </template-->
  </App>
</template>

<script>
import axios from 'axios'
import App from '../components/app.vue'
import AppBar from '../components/app-bar-with-drawer.vue'
// import Footer from '../components/copyright-footer.vue'

export default {
  name: 'home-view',
  async mounted () {
    const id = window.sessionStorage.getItem('u_id')
    if (id) await this.$router.push('/dashboard')
  },
  beforeUnmount () {
  },
  computed: {
  },
  data: () => ({
    account: null,
    password: null,
    showPassword: false,
    loginResult: null
  }),
  methods: {
    async Login () {
      this.loginResult = ''
      const response =
        await axios.post(
          `${window.AppConfig.api_url}/auth/login`,
          {
            account: this.account,
            password: this.password
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
      if (response.status === 200 && response.data.success) {
        console.log(response.data)
        const token = response.data.data.token
        const uId = response.data.data.u_id
        window.sessionStorage.setItem('token', token)
        window.sessionStorage.setItem('u_id', uId)
        await this.$router.push('/dashboard')
      } else {
        this.loginResult = '登入失敗, 請檢查網路後再試一次.'
      }
    }
  },
  components: {
    App: App,
    AppBar: AppBar
    // Footer: Footer
  }
}
</script>
<style scoped>
.home-view {
  background-color: white;
}
@media (min-width: 840px) {
  .app-bar {
    max-width: calc(840px - 30px);
    margin-left: calc((100vw - 840px) / 2);
  }
}
.home-main {
  min-height: 100%;
}
</style>
