<script>
export default {
  name: 'app-bar-with-drawer',
  data: () => ({
    drawer: false,
    group: null
  })
}
</script>

<template>
  <div>
    <v-app-bar
      app
      color="blue"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-1"
          contain
          :src="require('../assets/logo-128.jpg')"
          transition="scale-transition"
          width="56"
        />
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      </div>
      <v-btn text class="hidden-sm-and-down" @click="$router.push('/dashboard').catch(() => {})">儀錶板</v-btn>
      <v-btn text class="hidden-sm-and-down" @click="$router.push('/member').catch(() => {})">會員管理</v-btn>
      <v-btn text class="hidden-sm-and-down" @click="$router.push('/female').catch(() => {})">主播管理</v-btn>
      <v-btn text class="hidden-sm-and-down" @click="$router.push('/review').catch(() => {})">主播審核</v-btn>
      <v-btn text class="hidden-sm-and-down" @click="$router.push('/report').catch(() => {})">統計報表</v-btn>
      <v-btn text class="hidden-sm-and-down" @click="$router.push('/promotion').catch(() => {})">網紅推薦</v-btn>
      <slot name="menu"></slot>

    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      class="drawer"
      dark
    >
      <v-list
        nav
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title @click="$router.push('/dashboard').catch(() => {})">儀錶板</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/member').catch(() => {})">會員管理</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/female').catch(() => {})">主播管理</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/review').catch(() => {})">主播審核</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/report').catch(() => {})">統計報表</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="$router.push('/promotion').catch(() => {})">網紅推薦</v-list-item-title>
          </v-list-item>

          <slot name="list-item"></slot>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<style scoped>

</style>
